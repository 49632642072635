import type {IconProps} from "~/components/icons/IconProps"

export const MenuIcon = (props: IconProps) => {
  return (
    <svg
      className={props.className ?? "w-10 h-10"}
      width="54"
      height="54"
      viewBox="0 0 54 54"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M37 18.025C37 17.4589 36.5411 17 35.975 17H18.4625C17.6548 17 17 17.6548 17 18.4625V18.4625C17 19.2989 17.7002 19.965 18.5356 19.9232L36.0262 19.0487C36.5717 19.0214 37 18.5712 37 18.025V18.025Z"
        fill="currentColor"
      />
      <path
        d="M37 26.025C37 25.4589 36.5411 25 35.975 25H18.4625C17.6548 25 17 25.6548 17 26.4625V26.4625C17 27.2989 17.7002 27.965 18.5356 27.9232L36.0262 27.0487C36.5717 27.0214 37 26.5712 37 26.025V26.025Z"
        fill="currentColor"
      />
      <path
        d="M37 34.025C37 33.4589 36.5411 33 35.975 33H18.4625C17.6548 33 17 33.6548 17 34.4625V34.4625C17 35.2989 17.7002 35.965 18.5356 35.9232L36.0262 35.0487C36.5717 35.0214 37 34.5712 37 34.025V34.025Z"
        fill="currentColor"
      />
    </svg>
  )
}