import {CloseMenuIcon} from "~/components/icons/CloseMenuIcon"
import {MenuIcon} from "~/components/icons/MenuIcon"
import {LogoIcon} from "~/components/icons/LogoIcon"

export const MenuButton = ({isMenuOpen, onClick}: { isMenuOpen: boolean, onClick: () => void }) => {
  return (
    <button className="flex-shrink-0 flex px-1 rounded-full hover:bg-gray-100" onClick={onClick}>
      {isMenuOpen ? (
        <CloseMenuIcon className="-mr-3 my-auto w-7 h-7"/>
      ) : (
        <MenuIcon className="-mr-3 my-auto w-7 h-7"/>
      )}
      <LogoIcon
        className="size-12 rounded-full"
      />
    </button>
  )
}