import type {IconProps} from "~/components/icons/IconProps"
import React from "react"

export const CloseMenuIcon = (props: IconProps) => {
    return (
        <svg
            className={props.className ?? "w-10 h-10"}
            width="54"
            height="54"
            viewBox="0 0 54 54"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M35.1157 34.9407C35.5151 34.5413 35.5151 33.8938 35.1157 33.4944L21.3191 19.6977C20.7466 19.1253 19.8184 19.1253 19.246 19.6977V19.6977C18.655 20.2887 18.6769 21.2534 19.2941 21.8169L33.7029 34.9728C34.1067 35.3415 34.7291 35.3273 35.1157 34.9407V34.9407Z"
                fill="black"
            />
            <path
                d="M37 18.025C37 17.4589 36.5411 17 35.975 17H18.4625C17.6548 17 17 17.6548 17 18.4625C17 19.2989 17.7002 19.965 18.5356 19.9232L36.0262 19.0487C36.5717 19.0214 37 18.5712 37 18.025Z"
                fill="none"
            />
            <path
                d="M34.4407 18.3844C34.0413 17.985 33.3938 17.985 32.9944 18.3844L19.1977 32.181C18.6253 32.7535 18.6253 33.6816 19.1977 34.2541V34.2541C19.7887 34.8451 20.7534 34.8232 21.3169 34.206L34.4728 19.7971C34.8415 19.3933 34.8273 18.771 34.4407 18.3844V18.3844Z"
                fill="black"
            />
        </svg>
    )
}